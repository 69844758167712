// <div data-controller="components--subscribe-modal">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [
    "form", "modal", "name", "email", "pin",
    "confirmButtons", "authenticationField",
    "hiddenSubmit", "pinNotification",
    "confirmFreePlan", "confirmPaidPlan",
    "privacyAgreement", "adultAgreement", "autoPayAgreement"
  ];

  initialize() {
    this.pinSent = false;
  }

  showModal(initialPlan = undefined) {
    if (initialPlan === "paid") {
      this.formTarget.querySelector('input[name="subscription[plan]"][value="monthly"]').checked = true;
      this.forPaidPlan();
    }
    this.modalTarget.style.removeProperty("display");
    // document.body.classList.add("overflow-hidden");
  }

  hideModal() {
    this.modalTarget.style.display = "none";
    // document.body.classList.remove("overflow-hidden");
  }

  get autoPayAgreementContainer() {
    return document.getElementById("autoPayAgreement");
  }

  forFreePlan() {
    if (this.hasConfirmPaidPlanTarget) {
      this.confirmPaidPlanTarget.classList.add("hidden");
    }
    if (this.hasConfirmFreePlanTarget) {
      this.confirmFreePlanTarget.classList.remove("hidden");
    }
    if (this.hasAutoPayAgreementTarget) {
      this.autoPayAgreementTarget.required = false;
      this.autoPayAgreementContainer.classList.add("hidden");
    }
  }

  forPaidPlan() {
    if (this.hasConfirmFreePlanTarget) {
      this.confirmFreePlanTarget.classList.add("hidden");
    }
    if (this.hasConfirmPaidPlanTarget) {
      this.confirmPaidPlanTarget.classList.remove("hidden");
    }
    if (this.hasAutoPayAgreementTarget) {
      this.autoPayAgreementTarget.required = true;
      this.autoPayAgreementContainer.classList.remove("hidden");
    }
  }

  submitForm() {
    this.hiddenSubmitTarget.click();
  }

  startAuthentication() {
    if (!this.emailTarget.checkValidity()) {
      this.emailTarget.focus();
      return false;
    }

    if (this.hasPrivacyAgreementTarget) {
      if (!this.privacyAgreementTarget.checkValidity()) {
        this.privacyAgreementTarget.focus();
        return false;
      }
    }

    if (this.hasAdultAgreementTarget) {
      if (!this.adultAgreementTarget.checkValidity()) {
        this.adultAgreementTarget.focus();
        return false;
      }
    }

    if (this.hasAutoPayAgreementTarget) {
      if (!this.autoPayAgreementTarget.checkValidity()) {
        this.autoPayAgreementTarget.focus();
        return false;
      }
    }

    this.emailTarget.readOnly = true;
    this.nameTarget.readOnly = true;
    this.confirmButtonsTarget.style.display = "none";
    api.post("/api/users/send_pin", {email: this.emailTarget.value, name: this.nameTarget.value}).then((res) => {
      if (res.status === 200) {
        this.pinSent = true;
        this.authenticationFieldTarget.classList.remove("hidden");
        this.pinTarget.focus();
      } else if (res.status === 201) { // 대기 필요
        this.confirmButtonsTarget.style.removeProperty("display");
        alert(res.data.msg);
      } else {
        this.emailTarget.readOnly = false;
        this.nameTarget.readOnly = false;
        this.confirmButtonsTarget.style.removeProperty("display");
        alert(res.data.msg);
      }
    }).catch((e) => {
      const { msg } = e.response.data;
      this.emailTarget.readOnly = false;
      this.nameTarget.readOnly = false;
      this.confirmButtonsTarget.style.removeProperty("display");
      alert(msg);
      this.emailTarget.focus();
    })
  }

  resendPin(e) {
    e.target.classList.remove("text-green-700");
    e.target.classList.add("text-yellow-500");
    e.target.innerText = "발송중..";
    api.post("/api/users/send_pin", {email: this.emailTarget.value, name: this.nameTarget.value}).then((res) => {
      if (res.status === 200) {
        this.pinSent = true;
        e.target.classList.remove("text-yellow-500", "cursor-pointer");
        e.target.classList.add("text-green-700");
        e.target.innerText = "재발송완료 (스팸함도 확인해주세요)";
        e.target.dataset.action = null;
        this.pinTarget.focus();
        this.authenticationFieldTarget.classList.remove("hidden");
      } else {
        e.target.classList.remove("text-yellow-700");
        e.target.classList.add("text-red-700");
        e.target.innerText = res.data.msg || "발송실패 (재시도 하기)";
        this.confirmButtonsTarget.style.removeProperty("display");
      }
    })
  }



  connect() {
    const initialPlan = this.formTarget.querySelector('input[name="subscription[plan]"]:checked');
    if (initialPlan) {
      if (initialPlan.value === "free") {
        if (this.hasConfirmFreePlanTarget) {
          this.forFreePlan();
        }
      } else {
        if (this.hasConfirmPaidPlanTarget) {
          this.forPaidPlan();
        }
      }
    }

    if (this.data.get("initial-status") === "true") {
      this.showModal();
    }

    this.formTarget.addEventListener("keydown", (e) => {
      const keyCode = e.keyCode || e.which;
      const selectedPlan = this.formTarget.querySelector('input[name="subscription[plan]"]:checked').value;

      if (keyCode === 13 && !this.pinSent) {
        if (selectedPlan === "monthly" || selectedPlan === "yearly") {
          e.preventDefault();
          this.startAuthentication();
          return false;
        }
      }
    })
  }
}