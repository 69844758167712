// <div data-controller="notes--publish">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [
    "emailTitle", "dateTime", "priceContainer", "memberPriceInput",
    "priceInput",
    "publishingRecipientType", "publishingPostingType",
    "sendMailContainer",
    "emailTitleInput", "emailTitleWarn", "hiddenSendTestSubmit",
    "previewDataContainer", "adultOnlyCheckBox",
    "enablePreviewDataCheckBox",
    "previewDataCustomEditor", "previewDataDefaultView", "previewDataAdultOnlyView",
    "webVisibilityContainer",
    "senderEmailAddressContainer", "senderEmailAddressSelect",
    "subscriptionGroupContainer", "subscriptionGroupSelect",
    "recipientContainer", "recipientDescription"
  ];

  static values = {
    publishingRecipientTypesI18n: Object
  }

  onEmailTitleChange(e) {
    this.emailTitleTarget.innerText = e.target.value;
  }

  onDateTimeChange(e) {
    this.dateTimeTarget.innerText = new Date(e.target.value).toLocaleString('ko-KR');
  }

  checkEmailTitleAd(e) {
    if (e.target.value.includes("광고")) {
      this.emailTitleWarnTarget.innerText = "뉴스레터 제목에 '광고' 라는 단어가 포함되어 있네요. 실제로 광고가 포함되어 있으시다면 광고 문구를 반드시 넣어야 하지만, 그렇지 않은 경우에 넣으시면 광고가 아님에도 이메일이 프로모션 함으로 분류 될 가능성이 있습니다. 또한 법적으로 광고성 이메일의 경우에는 명시적으로 광고 수신에 동의하신 분들에게만 보낼 수 있습니다. 메일리에서는 기업용 메일리에 한해서 광고 수신 동의 기능을 제공하고 있으며, 수신 동의 하지 않은 사람들에게 광고를 보낼 경우 생기는 책임은 개별 뉴스레터에 있습니다.";
    } else {
      this.emailTitleWarnTarget.innerText = "";
    }
  }

  sendTest = (e) => {
    try {
      const clickedCommand = window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey;
      if (clickedCommand) {
        return;
      }
    } catch {
    }

    this.hiddenSendTestSubmitTarget.click();
  }

  togglePreviewDataEditor = () => {
    if (this.enablePreviewDataCheckBoxTarget.checked) {
      this.previewDataCustomEditorTarget.classList.remove("hidden");
      this.previewDataDefaultViewTarget.classList.add("hidden");
      this.previewDataAdultOnlyViewTarget.classList.add("hidden");
    } else {
      this.previewDataCustomEditorTarget.classList.add("hidden");
      if (this.isAdultOnly) {
        this.previewDataDefaultViewTarget.classList.add("hidden");
        this.previewDataAdultOnlyViewTarget.classList.remove("hidden");
      } else {
        this.previewDataDefaultViewTarget.classList.remove("hidden");
        this.previewDataAdultOnlyViewTarget.classList.add("hidden");
      }
    }
  }

  get checkedPublishingRecipientType() {
    const postingTypeRadio = document.querySelector('input[name="post[publishing_recipient_type]"]:checked')
    return postingTypeRadio && postingTypeRadio.value;
  }

  get checkedPublishingRecipientTypeDescription() {
    const postingTypeRadio = document.querySelector('input[name="post[publishing_recipient_type]"]:checked')
    console.log(postingTypeRadio);
    return postingTypeRadio && postingTypeRadio.dataset.description;
  }

  get checkedPublishingPostingType() {
    const postingTypeRadio = document.querySelector('input[name="post[publishing_posting_type]"]:checked')
    return postingTypeRadio && postingTypeRadio.value;
  }

  get isAdultOnly() {
    return this.hasAdultOnlyCheckBoxTarget && this.adultOnlyCheckBoxTarget.checked;
  }

  onPublishingTypeBlur() {
    if (this.checkedPublishingPostingType === "product") {
      this.priceContainerTarget.classList.remove("hidden")
      this.priceInputTarget.required = true;
      if (this.hasMemberPriceInputTarget) {
        this.memberPriceInputTarget.required = true;
      }
    } else {
      this.priceContainerTarget.classList.add("hidden")
      this.priceInputTarget.required = false;
      if (this.hasMemberPriceInputTarget) {
        this.memberPriceInputTarget.required = false;
      }
    }

    if (this.checkedPublishingRecipientType === "none") {
      this.sendMailContainerTargets.forEach((target) => {
        target.classList.add("hidden")
      })
      this.senderEmailAddressContainerTarget.classList.add("hidden")
      this.webVisibilityContainerTarget.classList.add("hidden")
      this.recipientContainerTarget.classList.add("hidden");
    } else {
      this.sendMailContainerTargets.forEach((target) => {
        target.classList.remove("hidden")
      })
      this.senderEmailAddressContainerTarget.classList.remove("hidden")
      this.webVisibilityContainerTarget.classList.remove("hidden")
      this.recipientContainerTarget.classList.remove("hidden");
    }

    if (["product", "membership_only"].includes(this.checkedPublishingPostingType) || this.isAdultOnly) {
      this.previewDataContainerTarget.classList.remove("hidden")
      this.togglePreviewDataEditor();
    } else {
      this.previewDataContainerTarget.classList.add("hidden")
      this.togglePreviewDataEditor();
    }

    if (this.hasSubscriptionGroupContainerTarget && this.hasSubscriptionGroupSelectTarget) {
      if (this.checkedPublishingRecipientType === "subscription_group") {
        this.subscriptionGroupContainerTarget.classList.remove("hidden");
        this.subscriptionGroupSelectTarget.required = true;
      } else {
        this.subscriptionGroupContainerTarget.classList.add("hidden");
        this.subscriptionGroupSelectTarget.required = false;
      }
    }

    if (this.checkedPublishingRecipientTypeDescription) {
      this.recipientDescriptionTarget.innerText = `✉️ ${this.checkedPublishingRecipientTypeDescription}`;
    }
  }

  connect() {
    this.onPublishingTypeBlur();
  }
}