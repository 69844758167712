// <div data-controller="components--clipboard">
//     <div class="btn btn-primary"
//         id="copyAction"
//         data-target="components--clipboard.copyText"
//         data-clipboard-text="<%= "#{request.base_url}/#{current_user.username}" %>"
//         data-action="click->components--clipboard#onClickCopy">복사하기
//     </div>
// </div>
import { Controller } from "@hotwired/stimulus";
import ClipboardJS from 'clipboard';

export default class extends Controller {
  static targets = [ "copyText"];

  connect() {
    this.clipboard = new ClipboardJS(this.copyTextTarget);
    this.clipboard.on("success", (e) => {
      // alert("복사되었습니다.")
    })
  }

  disconnect() {
    this.clipboard.destroy()
  }
}